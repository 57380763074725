import React from 'react'
import Navbars from "../components/Navbar/Navbars"
import "./style.css"
export default function NoPage() {
  return (
    <div className='notFound'>
      <Navbars />
    </div>
  )
}
